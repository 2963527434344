import { REDIRECT, APP_RESET } from "./actionTypes";

export const redirect = (link) => {
  console.log("REDIRECTION REQUEST --", link);

  return { type: REDIRECT, payload: link };
};

export const appReset = () => {
  return { type: APP_RESET };
};
