import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => (
  <div>
    <div className="jumbotron">
      The New Mexico Battle of the Books is a reading competition for students in grades
      4-8. Children read and meet with their peers throughout the year, then enter a
      quiz-style battle, with four rounds, in which they are asked about books that they
      have read from the year’s designated Battle of the Books reading list. The New
      Mexico Battle of the Books encourages the youth of New Mexico to read high quality
      literature, have fun, and compete by demonstrating their knowledge of books.
      <br />
      <br />
      <Link
        to="https://nmlibraries.org/battleofthebooks/"
        className="btn btn-secondary btn-sm"
      >
        See the official website for more details...
      </Link>
      <br />
    </div>
    <Link to="register" className="btn btn-primary btn-lg">
      Register and start your online battle
    </Link>
  </div>
);

export default HomePage;
