import React, { useState, Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { Link } from "react-router-dom";

const history = createBrowserHistory();
import * as battleActions from "../../store/actions/participantActions";
import { getGradeLevels } from "../../store/actions/gradeLevelActions";
import { fetchBooksIfNeeded } from "../../store/actions/bookActions";
import BattleStartPage from "../Battle/BattleStartPage";
import { useAppContext } from "../../context/context";
import { appReset } from "../../store/actions/utilityActions";

const debugThisFile = false;
const inputParsers = {
  date: (input) => {
    const [month, day, year] = input.split("/");
    return `${year}-${month}-${day}`;
  },
  uppercase: (input) => {
    return input.toUpperCase();
  },
  numbers: (input) => {
    return parseFloat(input);
  },
  doNothing: (input) => {
    return input;
  },
};

class RegistrationPage extends Component {
  constructor(props) {
    super(props);
    var newId, overallMsg;
    this.state = {
      action: "register",
      participant: {
        level: "",
        teamName: "",
        school: "",
        teacher: "",
      },
      formStatus: "",
      participantStatus: "",
      newId: "",
      overallMsg: null,
      bookChk: {},
      validated: false,
    };
    this.props.appReset();
  }

  setValidated = (flg) => {
    this.setState({ ...this.state, validated: flg });
  };

  componentDidMount() {
    this.props.loadGradeLevels();
    //this.props.loadBooks();
  }

  verifyRegistration = () => {
    var bOk = true;
    var msg = "Missing Required Fields: ";
    var div = "";
    if (this.state.participant.level == "") {
      bOk = false;
      msg += div + "Grade Level";
      div = ", ";
    }
    if (this.state.participant.school == "") {
      bOk = false;
      msg += div + "School Name";
      div = ", ";
    }
    if (this.state.participant.teacher == "") {
      bOk = false;
      msg += div + "Teacher's Name";
      div = ", ";
    }

    if (bOk) {
      msg = "";
    }
    return msg;
  };

  onRegister = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("CheckValidity");

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const curState = { ...this.state };

      const data = new FormData(form);
      for (let nm of data.keys()) {
        console.log("00000000000000000000000000000000000");
        console.log(nm);
        console.log(data.get(nm));
        console.log("00000000000000000000000000000000000");

        const val = data.get(nm);
        curState.participant[nm] = val;
      }

      console.log("curState", curState);
      this.setState(curState);

      this.props.registerTeam(curState.participant);
    }
    this.setValidated(true);
  };

  eventPrep = (event) => {
    event.preventDefault();
    return {
      state: { ...this.state },
      eventTargetId: event.target.id,
      eventValue: event.target.value,
    };
  };

  onEvent = (event) => {
    var data = this.eventPrep(event);
    var bUpdate = false;
    switch (data.eventTargetId) {
      case "level":
      case "gradeLevel":
        data.state.participant.level = data.eventValue;
        bUpdate = true;
        break;
      case "teamname":
      case "school":
      case "teacher":
        data.state.participant[data.eventTargetId] = data.eventValue;
        bUpdate = true;
        break;
      default:
        break;
    }
    if (bUpdate) {
      this.setState(data.state);
    }
  };

  /*
  startBattle = (event) => {
    if (this.props.newId) {
      this.setState({ ...this.state, battleId: "btl1", action: "gobattle" });
      this.props.history.push({
        pathname: "/battle/start",
        state: { battleId: "btl1", teamId: this.state.newId, questionNdx: -22 },
      });
    }
  };
  */

  /*
  goBattle = (teamId, battleId = "btl1") => {
    if (debugThisFile) debugger;

    this.props.history.push({
      pathname: "/battle/start",
      state: { battleId, teamId, questionNdx: -22 },
    });
  };
  */

  genForm = () => {
    return (
      <div>
        <Form noValidate validated={this.state.validated} onSubmit={this.onRegister}>
          <Form.Row>
            <Form.Group
              className="col-sm-12 col-md-8 col-lg-8"
              controlId="registrationSchool"
            >
              <Form.Label>School Name</Form.Label>
              <Form.Control name="school" type="text" onChange={this.onEvent} required />
              <Form.Control.Feedback type="invalid">
                {" "}
                Please enter the name of your school{" "}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid"> School selected</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-sm-12 col-md-4 col-lg-4"
              controlId="registrationGradeLevel"
            >
              <Form.Label>Grade Level</Form.Label>
              <Form.Control
                as="select"
                name="level"
                onChange={this.onEvent}
                required
                placeholder=""
              >
                <option key="noSelection" value=""></option>
                {this.props.gradeLevels.map((level) => (
                  <option key={level.internalId} value={level.internalId}>
                    {level.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Please select your grade level.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              controlId="registrationTeacher"
            >
              <Form.Label>Participant First Name</Form.Label>
              <Form.Control
                name="firstName"
                type="text"
                onChange={this.onEvent}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter your first name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              controlId="registrationTeacher"
            >
              <Form.Label>Teacher / Sponsor</Form.Label>
              <Form.Control name="teacher" type="text" onChange={this.onEvent} required />
              <Form.Control.Feedback type="invalid">
                {" "}
                Please enter the name of the teacher / sponsor.{" "}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              controlId="registrationEmail"
            >
              <Form.Label>E-mail</Form.Label>
              <Form.Control name="email" type="email" onChange={this.onEvent} required />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address for the teacher / sponsor.{" "}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Button variant="primary" type="submit">
            Check Registration
          </Button>
          <div>{this.state.formStatus}</div>
          <div>{this.props.participantStatus}</div>
          <div>{this.props.overallMsg}</div>
        </Form>
      </div>
    );
  };

  render() {
    var frm;
    if (this.props.redirect) {
      //this.props.redirectTo,
      if (debugThisFile) debugger;

      frm = <Redirect to={{ ...this.props.redirect }} />;
      //frm = <BattleStartPage {...this.state} />;
      /*
    } else if (this.props.newId) {
      if(debugThisFile){debugger;}
      frm = (
        <div className="col-xs-12">
          {Object.keys(this.props.bookChk).map(this.createBookSelectionKey)}
          <input type="button" value="GO Battle" onClick={this.startBattle} />
          <Link
            to={{
              pathname: "/battle/start",
              state: { battleId: "btl1", teamId: this.state.newId, questionNdx: -1 },
            }}
          >
            Go Battle{" "}
          </Link>
        </div>
      );
      */
    } else {
      frm = this.genForm();
    }

    return <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">{frm}</div>;
  }
}

RegistrationPage.propTypes = {
  gradeLevels: PropTypes.array.isRequired,
  registerTeam: PropTypes.func.isRequired,
  participantStatus: PropTypes.string.isRequired,
  loadGradeLevels: PropTypes.func.isRequired,
  loadBooks: PropTypes.func.isRequired,
};

///////////////////////////////////////////////////////////////////////////////////////////
const mapState = (state /*, ownProps*/) => {
  var overallMsg;
  var redirect;
  var bookChk = {};

  if (state.gradeLevels.error) {
    overallMsg = state.gradeLevels.error.message;
  }

  var participant = state.battleQuiz.participant; //participant.item ? state.participant.item : null;
  var participantId = participant._id; //participant ? participant._id : null;
  var participantStatus =
    state.participant.loading || state.battleQuiz.loading
      ? "In Progress"
      : state.participant.error || state.battleQuiz.error
      ? state.participant.error.msg || "Please Retry"
      : ""; //"id: " + participantId;

  if (participantId) {
    if (debugThisFile) debugger;

    redirect = { pathname: "/battle/start", state: { participant } };
  }

  /*
  state.books.items.map((bk) => {
    bookChk[bk.internalId] = { ...bk, checked: "off" };
  });
  */

  return {
    gradeLevels: state.gradeLevels.items,
    participantStatus,
    newId: participantId,
    newParticipant: { ...participant },
    redirect,
    overallMsg,
    bookChk,
  };
  //participant,
};

const mapDispatch = (dispatch) => {
  return {
    loadGradeLevels: () => dispatch(getGradeLevels()),
    appReset: () => dispatch(appReset()),
    loadBooks: () => dispatch(fetchBooksIfNeeded()),
    registerTeam: (participant, nxt) => {
      dispatch(battleActions.registerTeam(participant));
      if (nxt) {
        nxt();
      }
    },
  };
};

export default connect(mapState, mapDispatch)(RegistrationPage);
