import {
  REGISTER_PARTICIPANT_BEGIN,
  REGISTER_PARTICIPANT_FAIL,
  REGISTER_PARTICIPANT_SUCCESS,
  REGISTER_TEAM_BEGIN,
  REGISTER_TEAM_FAIL,
  REGISTER_TEAM_SUCCESS,
} from "./actionTypes";
import { getData, postData } from "./actionBase";
import fetch from "node-fetch";

var debugThisFile = false;

////////////////////////////////////////////////////////////////////
//var baseurl = "http://localhost:" + serverConfig.port;

//var teamBaseUrl = baseurl + "/teams";
//var teamRegisterUrl = baseurl + "/register/team";
import { makeURL } from "./../../api/apiUtils";
var teamRegisterUrl = makeURL("register", "team");

////////////////////////////////////////////////////////////////////
export const registrationSuccess = (resp) => {
  if (debugThisFile) debugger;

  var payload = resp.data ? resp.data : resp;
  return { type: REGISTER_PARTICIPANT_SUCCESS, payload };
};
export const registrationFail = (resp) => {
  var error = resp.status ? { ...resp.status } : resp;
  return { type: REGISTER_PARTICIPANT_FAIL, payload: { error } };
};
export const registrationInProgress = () => {
  return { type: REGISTER_PARTICIPANT_BEGIN };
};

export const teamRegistrationSuccess = (resp) => {
  var payload = resp.data ? { ...resp.data } : resp;
  payload.battleId = payload.battleId || "btl1";
  return { type: REGISTER_TEAM_SUCCESS, payload };
};
export const teamRegistrationFail = (resp) => {
  var error = resp.status ? resp.status : resp;
  if (debugThisFile) debugger;

  return { type: REGISTER_TEAM_FAIL, payload: { error } };
};
export const teamRegistrationInProgress = () => {
  return { type: REGISTER_TEAM_BEGIN };
};
////////////////////////////////////////////////////////////////////
/*export const getGradeLevels = () => {
  return getData(
    baseurl,
    loadGradeLevelsBegin,
    loadGradeLevelsSuccess,
    loadGradeLevelsFail
  );
};:w
*/

export const registerTeam = (teamInfo, nxt) => {
  var localSuccess = (resp) => {
    if (nxt) {
      if (debugThisFile) debugger;

      teamRegistrationSuccess(resp);
      return nxt(resp.data._id);
    } else {
      return teamRegistrationSuccess(resp);
    }
  };

  return postData(
    teamRegisterUrl,
    teamInfo,
    teamRegistrationInProgress,
    localSuccess,
    teamRegistrationFail
  );
};
