import React from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";

var debugThisFile = false;
//import './App.css';
class DisplayAnswerSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookGuess: "",
      authorGuess: "",
      formValidated: false,
    };
  }

  eventPrep = (event) => {
    event.preventDefault();
    return {
      state: { ...this.state },
      eventTargetId: event.target.id,
      eventValue: event.target.value,
    };
  };

  onEvent = (event) => {
    var data = this.eventPrep(event);
    var bUpdate = false;
    switch (data.eventTargetId) {
      case "bookGuess":
      case "authorGuess":
        data.state[data.eventTargetId] = data.eventValue;
        bUpdate = true;
        break;
      default:
        break;
    }

    if (bUpdate) {
      this.setState(data.state);
    }
    this.props.chngeMethod(event);
  };

  render() {
    if (debugThisFile) debugger;
    return (
      <div>
        <Form.Group
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          controlId="bookGuessCtrl"
        >
          <Form.Label>Select the book:</Form.Label>
          <Form.Control
            as="select"
            name="bookGuess"
            onChange={this.onEvent}
            required
            placeholder=""
          >
            <option key="noSelection" value=""></option>
            {this.props.books.map((book) => (
              <option key={book._id} value={book._id}>
                {" "}
                {book.name}{" "}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select your guess.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          controlId="authorGuessCtrl"
        >
          <Form.Label>Select the author:</Form.Label>
          <Form.Control
            as="select"
            name="authorGuess"
            onChange={this.onEvent}
            required
            placeholder=""
          >
            <option key="noSelection" value=""></option>
            {this.props.writers.map((author) => (
              <option key={author._id} value={author._id}>
                {" "}
                {author.name}{" "}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select your guess.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    );
  }
}

function mapStateToProps(state /*ownProps*/) {
  return {
    books: state.books.items,
    writers: state.authors.items,
  };
}

export default connect(mapStateToProps)(DisplayAnswerSet);
