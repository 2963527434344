import { combineReducers } from "redux";
import books from "./bookReducers";
import authors from "./authorReducers";
import gradeLevels from "./gradeLevelReducers";
import participant from "./participantReducers";
import battleQuiz from "./battleQuizReducers";
import battleRuns from "./battleReducers";
import results from "./resultsReducers";
import { reducerinitialState } from "../storeDefaults";
import { REDIRECT, APP_RESET } from "../actions/actionTypes";
import { sessionReducer } from "redux-react-session";

const dummyReducer = (state = reducerinitialState, action) => state;

//session: sessionReducer,
const appReducers = combineReducers({
  results,
  battleQuiz,
  gradeLevels,
  books,
  authors,
  participant,
  battleRuns,
  battles: dummyReducer,
  participants: dummyReducer,
  battleQuestions: dummyReducer,
  battle: dummyReducer,
  battleAnswers: dummyReducer,
  questions: dummyReducer,
  correctAnswers: dummyReducer,
  registrationQuestions: dummyReducer,
  active: dummyReducer,
  teams: dummyReducer,
  registrations: dummyReducer,
  redirect: (state = { redirectTo: "" }, action) => {
    switch (action.type) {
      case REDIRECT:
        return { redirectTo: action.payload };
      default:
        return state;
    }
  },
});

const rootReducers = (state, action) => {
  if (action.type === APP_RESET) {
    state = undefined;
  }

  return appReducers(state, action);
};

export default rootReducers;
