import fetch from "cross-fetch";

import {
  LOAD_BATTLE_BEGIN,
  LOAD_BATTLE_FAIL,
  LOAD_BATTLE_SUCCESS,
  LOAD_BATTLE_RESET,
  START_BATTLE_BEGIN,
  START_BATTLE_RESET,
  START_BATTLE_FAIL,
  START_BATTLE_SUCCESS,
  ANSWER_QUESTION_BEGIN,
  ANSWER_QUESTION_FAIL,
  ANSWER_QUESTION_RESET,
  ANSWER_QUESTION_SUCCESS,
  GET_RESULTS_BEGIN,
  GET_RESULTS_FAIL,
  GET_RESULTS_RESET,
  GET_RESULTS_SUCCESS,
} from "./actionTypes";
import { getData, postData } from "./actionBase";
import { makeURL } from "./../../api/apiUtils";
import { loadAuthorsFail } from "./authorActions";

var debugThisFile = false;
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
export const loadBattleSuccess = (json) => {
  if (debugThisFile) debugger;
  var payload = json.data;
  return { type: LOAD_BATTLE_SUCCESS, payload };
};
export const loadBattleFail = (error) => {
  return { type: LOAD_BATTLE_FAIL, payload: error };
};
export const loadBattleBegin = () => {
  return { type: LOAD_BATTLE_BEGIN };
};
export const resetBattle = () => {
  return { type: LOAD_BATTLE_RESET };
};

///////////////////////////////////////////////////////////////////////////
export const answerQuestionSuccess = (json) => {
  if (debugThisFile) debugger;
  return loadBattleSuccess(json);
};
export const answerQuestionFail = (error) => {
  return { type: ANSWER_QUESTION_FAIL, payload: error };
};
export const answerQuestionBegin = () => {
  return loadBattleBegin();
};
export const resetQuestion = () => {
  return { type: ANSWER_QUESTION_RESET };
};
///////////////////////////////////////////////////////////////////////////
export const startBattleSuccess = (json) => {
  var payload = json.data;
  return { type: START_BATTLE_SUCCESS, payload };
};
export const startBattleFail = (error) => {
  return { type: START_BATTLE_FAIL, payload: error };
};
export const startBattleBegin = () => {
  return { type: START_BATTLE_BEGIN };
};
export const restartBattle = () => {
  return { type: START_BATTLE_RESET };
};

///////////////////////////////////////////////////////////////////////////
export const getResultsSuccess = (json) => {
  var payload = json;
  return { type: GET_RESULTS_SUCCESS, payload };
};
export const getResultsFail = (error) => {
  return { type: GET_RESULTS_FAIL, payload: error };
};
export const getResultsBegin = () => {
  return { type: GET_RESULTS_BEGIN };
};
export const restartResults = () => {
  return { type: GET_RESULTS_RESET };
};

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
var baseUrl = makeURL("quiz");

export const getBattleQuestion = (teamId, battleId, questionNdx) => {
  return (dispatch) => {
    if (debugThisFile) debugger;
    var data = {
      active: {
        teamId,
        battleId,
        questionNdx,
      },
    };

    const localSuccess = (json) => {
      if (debugThisFile) debugger;

      json.questionNdx = questionNdx;
      return loadBattleSuccess(json);
    };

    return postData(
      baseUrl,
      data,
      loadBattleBegin,
      localSuccess,
      loadAuthorsFail
    )(dispatch);
  };
};

export const answerBattleQuestion = (
  teamId,
  battleId,
  runId,
  questionId,
  questionNdx,
  book,
  author
) => {
  if (debugThisFile) debugger;
  return (dispatch) => {
    if (debugThisFile) debugger;
    var data = { teamId, battleId, runId, questionId, questionNdx, book, author };

    const localSucess = (json) => {
      if (debugThisFile) debugger;

      if (debugThisFile) debugger;

      if (!json.complete) json.questionNdx = questionNdx;

      return answerQuestionSuccess(json);
    };

    return postData(
      baseUrl + "/answer",
      data,
      answerQuestionBegin,
      localSucess,
      answerQuestionFail
    )(dispatch);
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const createBattleRun = (participantObj) => {
  return (dispatch) => {
    var params = { ...participantObj };
    if (debugThisFile) debugger;
    console.log("create battle run: ", baseUrl + "/newRun");

    return postData(
      baseUrl + "/newRun",
      params,
      startBattleBegin,
      startBattleSuccess,
      startBattleFail
    )(dispatch);
  };
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getResults = (runId) => {
  const processResults = (json) => {
    var data = [];
    if (debugThisFile) debugger;
    json.data.ansSet.forEach((answer) => {
      if (debugThisFile) debugger;
      var q = json.data.ques.find((e) => e._id == answer.questionId);
      var c = json.data.answers[q.internalId];
      var elm = { response: { ...answer }, ques: q, ca: c };
      data[answer.questionNdx - 1] = elm;
    });
    //data.ansSet = []; data.ques = {}; data.answers = {};

    return getResultsSuccess(data);
  };

  return (dispatch) => {
    if (debugThisFile) debugger;
    debugger;
    console.log("create battle run: ", baseUrl + "/results/" + runId);

    return getData(
      baseUrl + "/results/" + runId,
      getResultsBegin,
      processResults, //success
      getResultsFail
    )(dispatch);
  };
};
