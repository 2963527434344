import {
  GET_RESULTS_BEGIN,
  GET_RESULTS_SUCCESS,
  GET_RESULTS_FAIL,
  GET_RESULTS_RESET,
} from "../actions/actionTypes";
import { resultReducerInitialState } from "../storeDefaults";
import reducerReturns from "./reducerUtils";

//{ loading: false, error: null, battleAnswers: [], correctAnswers: {}, questions: {}, };
export default function getResultsReducer(state = resultReducerInitialState, action) {
  switch (action.type) {
    case GET_RESULTS_RESET:
      return {
        loading: false,
        error: null,
        responses: [],
      };

    case GET_RESULTS_BEGIN:
      return { ...state, loading: true };

    case GET_RESULTS_SUCCESS:
      return { ...state, loading: false, error: null, responses: action.payload };

    case GET_RESULTS_FAIL:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return reducerReturns.defaultReturn(state);
  }
}
