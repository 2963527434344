import {
  LOAD_BATTLE_BEGIN,
  LOAD_BATTLE_SUCCESS,
  LOAD_BATTLE_FAIL,
  LOAD_BATTLE_RESET,
  REGISTER_TEAM_SUCCESS,
  REGISTER_TEAM_BEGIN,
  REGISTER_TEAM_FAIL,
  ANSWER_QUESTION_SUCCESS,
} from "../actions/actionTypes";
import { quizInitialState } from "../storeDefaults";
import reducerReturns from "./reducerUtils";

var debugThisFile = false;
export default function battleReducer(state = quizInitialState, action) {
  switch (action.type) {
    case LOAD_BATTLE_RESET:
      return {
        ...reducerReturns.resetDefaultReturn(state),
        isValid: false,
        complete: false,
      };

    case LOAD_BATTLE_BEGIN:
      return {
        ...reducerReturns.beginDefaultReturn(state),
        isValid: false,
        complete: false,
      };

    case ANSWER_QUESTION_SUCCESS:
      if (debugThisFile) debugger;
    // eslint-disable-next-line no-fallthrough
    case LOAD_BATTLE_SUCCESS:
      var battle = action.payload.battleId;
      var question = { ...action.payload.question };
      var complete =
        action.payload && action.payload.complete ? action.payload.complete : false;
      if (debugThisFile) debugger;

      return {
        ...state,
        loading: false,
        isValid: true,
        complete,
        payload: { battle, question },
      };

    case LOAD_BATTLE_FAIL:
      return {
        ...reducerReturns.failDefaultReturn(state, action),
        isValid: false,
        complete: false,
      };

    case REGISTER_TEAM_SUCCESS:
      if (debugThisFile) debugger;

      return { ...state, loading: false, participant: action.payload };

    case REGISTER_TEAM_BEGIN:
      return { ...state, loading: true, error: null };

    case REGISTER_TEAM_FAIL:
      return {
        ...state,
        Resp: action.payload.msg,
        loading: false,
        error: action.payload.error,
        participant: {},
        items: [],
      };

    default:
      return reducerReturns.defaultReturn(state);
  }
}
