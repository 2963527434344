import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import "./index.css";
import configureStore from "./store/configureStore";
//import "bootswatch/dist/slate/bootstrap.min.css"; // Added this :boom:
import { defaultState } from "./server/defaultState";
import { loadBooksBegin } from "./store/actions/bookActions";
import { history } from "./store/history";

/*
import { makeImgUrl } from "./api/apiUtils";
var appStyle = { color: "black", backgroundImage: makeImgUrl("Bob-bg.jpg") };
      <div style={appStyle}>
      </div>
      */
/*
function Test() {
  return <p> -- NMBOB -- </p>;
}
    //<Test />
*/

const store = configureStore({});

//store.dispatch(loadBooksBegin()).then(() => console.log(store.getState()));

render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById("app")
);
