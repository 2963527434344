import React from "react";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import Player from "react-player";
import PropTypes from "prop-types";

import "../../../node_modules/video-react/dist/video-react.css"; // import css
//import { Player, ControlBar } from "video-react";

var debugThisFile = false;
class DisplayQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      qtxt: props.question.qtxt,
      dispTxt: props.question.url != "" ? "Click video to start" : props.question.qtxt,
    };
  }

  startvideo = () => {
    if (!this.state.running) {
      var txt = this.state.qtxt;
      this.setState({ ...this.state, running: true, dispTxt: txt });
    }
  };

  displayPlayer = (url) => {
    if (url == "") {
      return; //( <Alert key="novideo" variant="dark" style={{ fontSize: 10 }}> Video not available </Alert>);
    } else {
      return <Player url={url} onStart={this.startvideo} />;
    }
  };
  render() {
    if (debugThisFile) debugger;
    return (
      <div>
        <table className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <tbody>
            <tr>
              <th>Question: {this.props.questionNdx}</th>
            </tr>
            <tr>
              <th>{this.displayPlayer(this.props.question.url)}</th>
            </tr>
            <tr>
              <th>
                <hr />
              </th>
            </tr>
            <tr>
              <th>
                <Alert key="questionTxt" variant="info">
                  {this.state.dispTxt}
                </Alert>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

DisplayQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  if (debugThisFile) debugger;
  return { ...ownProps };
}

export default connect(mapStateToProps)(DisplayQuestion);
