import React from "react";
import { NavLink } from "react-router-dom";
//import './App.css';

const NMBoBNavBar = () => {
  const activeStyle = { color: "#FF00AF" };

  return (
    <nav>
      <NavLink to="/" activeStyle={activeStyle} exact>
        Home
      </NavLink>
    </nav>
  );
};

export default NMBoBNavBar;

//{" | "} <NavLink to="/about" activeStyle={activeStyle} exact> About </NavLink> {" | "} <NavLink to="/books" activeStyle={activeStyle} exact> Books </NavLink>
