import {
  LOAD_GRADES_BEGIN,
  LOAD_GRADES_SUCCESS,
  LOAD_GRADES_FAIL,
} from "../actions/actionTypes";
import { reducerinitialState } from "../storeDefaults";

//const initialState = { items: [], loading: false, error: null };

export default function gradeLevelReducers(state = reducerinitialState, action) {
  switch (action.type) {
    case LOAD_GRADES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOAD_GRADES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case LOAD_GRADES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
