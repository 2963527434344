import React from "react";
import { connect } from "react-redux";
import * as bookActions from "../../store/actions/bookActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import lmgLogGen, { lmgLogError } from "../../util/logging";
var lmgLog = lmgLogGen(true);

class BooksPage extends React.Component {
  state = {
    book: {
      title: "",
      author: "",
      id: "",
    },
  };
  /* simplify out constructor
  constructor(props) {
    super(props);

    this.state = {
      book: {
        title: "",
        author: "",
        id: "",
      },
    };
  }
  */

  titleChange = (event) => {
    const book = { ...this.state.book, title: event.target.value };
    this.setState({ book });
  };
  authorChange = (event) => {
    const book = { ...this.state.book, author: event.target.value };
    this.setState({ book });
  };

  idChange = (event) => {
    const book = { ...this.state.book, id: event.target.value };
    this.setState({ book });
  };

  submitBook = (event) => {
    event.preventDefault();
    lmgLog(this.state.book);

    //    this.props.dispatch(bookActions.createBook(this.state.book));
    this.props.bookActions.createBook(this.state.book);
  };

  render() {
    return (
      <div>
        <form onSubmit={this.submitBook}>
          <h2>Book List</h2>
          <h3>Add Book</h3>
          <label>
            Title:{" "}
            <input
              type="text"
              onChange={this.titleChange}
              value={this.state.book.title}
            />
          </label>
          <br />
          <label>
            Author:
            <input
              type="text"
              onChange={this.authorChange}
              value={this.state.book.author}
            />
          </label>
          <br />
          <label>
            id:
            <input type="text" onChange={this.idChange} value={this.state.book.id} />
          </label>
          <input type="submit" value="Save" />
          {this.props.books.map((book) => (
            <div key={book.id}>{book.title}</div>
          ))}
        </form>
      </div>
    );
  }
}

BooksPage.propTypes = {
  books: PropTypes.array.isRequired,
  //dispatch: PropTypes.func.isRequired,
  //createBook: PropTypes.func.isRequired,
  bookActions: PropTypes.object.isRequired,
};

function mapStateToProps(state /*ownProps*/) {
  return {
    books: state.books,
  };
}

function mapDispatchToProps(dispatch /* ownProps*/) {
  return {
    //createBook: (book) => dispatch(bookActions.createBook(book)),
    bookActions: bindActionCreators(bookActions, dispatch),
  };
}

/*
const mapDispatchToProps = {
    createBook: bookActions.createBook
}
*/

export default connect(mapStateToProps, mapDispatchToProps)(BooksPage);
