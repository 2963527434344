import {
  LOAD_AUTHORS_BEGIN,
  LOAD_AUTHORS_SUCCESS,
  LOAD_AUTHORS_FAIL,
  LOAD_AUTHORS_RESET,
} from "../actions/actionTypes";
import { reducerinitialState } from "../storeDefaults";
import reducerReturns from "./reducerUtils";

export default function authorReducer(state = reducerinitialState, action) {
  switch (action.type) {
    case LOAD_AUTHORS_RESET:
      return reducerReturns.resetDefaultReturn(state);

    case LOAD_AUTHORS_BEGIN:
      return reducerReturns.beginDefaultReturn(state);

    case LOAD_AUTHORS_SUCCESS:
      return reducerReturns.successDefaultReturn(state, action);

    case LOAD_AUTHORS_FAIL:
      return reducerReturns.failDefaultReturn(state, action);

    default:
      return reducerReturns.defaultReturn(state);
  }
}
