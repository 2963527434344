const sysconfig = {
  status: true,
};

const boarderLine = (ind = "") =>
  console.log("----------------------------------------------", ind);

const lmgLogGen = (active = true, rptlvl = -1, boarders = false) => {
  console.log("USE BORDERS? ", boarders);

  if (sysconfig.status && active) {
    if (rptlvl >= 0) {
      return (lvl, ...args) => {
        if (lvl <= rptlvl) {
          if (boarders) boarderLine("start");
          console.log.apply(this, args);
          if (boarders) boarderLine("endof");
        }
      };
    } else {
      return (...args) => {
        if (boarders) boarderLine();
        console.log.apply(this, args);
        if (boarders) boarderLine();
      };
    }
  } else {
    return () => {};
  }
};

export const lmgLogError = (...args) => {
  console.log.apply(this, args);
};

export default lmgLogGen;
