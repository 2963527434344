import {
  REGISTER_TEAM_BEGIN,
  REGISTER_TEAM_SUCCESS,
  REGISTER_TEAM_FAIL,
} from "../actions/actionTypes";
import { reducerinitialState } from "../storeDefaults";
reducerinitialState.item = {};

var debugThisFile = false;
export default function participantReducer(state = reducerinitialState, action) {
  switch (action.type) {
    case REGISTER_TEAM_BEGIN:
      return { ...state, loading: true, error: null };

    case REGISTER_TEAM_SUCCESS:
      if (debugThisFile) debugger;

      return { ...state, loading: false, item: action.payload };

    case REGISTER_TEAM_FAIL:
      return {
        ...state,
        Resp: action.payload.msg,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
