////////////////////////////////////////
////////////////////////////////////////
export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

////////////////////////////////////////
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

import { serverConfig } from "../server/serverConfig";

//const port = serverConfig.port;
//const baseurl = "http://localhost:" + port;
var apiURL = process.env.API_URL;
var apiPort = process.env.API_PORT;
var baseurl = apiURL + ":" + apiPort;

export const makeURL = (...pieces) => {
  var ret = baseurl;
  pieces.forEach((piece) => {
    ret += "/" + piece;
  });
  return ret;
};

export const makeImgUrl = (imgName) => {
  return makeURL("imgs", imgName);
};
