import { fetchAuthors, fetchAuthorsIfNeeded } from "./authorActions";
import { fetchBooks, fetchBooksIfNeeded } from "./bookActions";
import {
  getBattleQuestion,
  answerBattleQuestion,
  createBattleRun,
  getResults,
} from "./battleQuizActions";

const verifyBooksAuthorsLoaded = (simple) => {
  return (dispatch, getState) => {
    fetchBooksIfNeeded(simple)(dispatch, getState);
    fetchAuthorsIfNeeded(simple)(dispatch, getState);
    return;
  };
};

export const quizSupportActions = {
  verifyBooksAuthorsLoaded,
  getBattleQuestion,
  answerBattleQuestion,
  createBattleRun,
  getResults,
};

export const storeActions = {
  fetchAuthors,
  fetchAuthorsIfNeeded,
  fetchBooks,
  fetchBooksIfNeeded,
};

export default storeActions;
