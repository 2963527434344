export const basicRedurcerInitialState = { loading: false, error: null };

export const resultReducerInitialState = {
  ...basicRedurcerInitialState,
  responses: [],
};
export const reducerinitialState = {
  ...basicRedurcerInitialState,
  items: [],
  loading: false,
  error: null,
};
export const activeInitialState = {
  ...basicRedurcerInitialState,
  error: null,
  loading: false,
  world: { teamId: "", battleId: "", questionId: "", questionNdx: "" },
};

export const quizInitialState = {
  ...basicRedurcerInitialState,
  error: null,
  loading: false,
  isValid: false,
  complete: false,
  payload: { battle: "", question: { qtxt: "", url: "", ndx: -1 } },
  participant: {},
};
