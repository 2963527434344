import { LOAD_GRADES_BEGIN, LOAD_GRADES_SUCCESS, LOAD_GRADES_FAIL } from "./actionTypes";
import { getData } from "./actionBase";

var debugThisFile = false;
////////////////////////////////////////////////////////////////////
//import { serverConfig } from "../../server/serverConfig";
//var baseurl = "http://localhost:" + serverConfig.port + "/entities/gradeLevels";
import { makeURL } from "./../../api/apiUtils";
var baseurl = makeURL("entities", "gradeLevels");

////////////////////////////////////////////////////////////////////
export const loadGradeLevelsSuccess = (resp) => {
  var payload = resp.data ? resp.data : resp;
  return { type: LOAD_GRADES_SUCCESS, payload };
};
export const loadGradeLevelsFail = (resp) => {
  var error = resp.status ? resp.status : resp;
  return { type: LOAD_GRADES_FAIL, payload: { error } };
};
export const loadGradeLevelsBegin = () => {
  return { type: LOAD_GRADES_BEGIN };
};

////////////////////////////////////////////////////////////////////
export const getGradeLevels = () => {
  if (debugThisFile) debugger;

  return getData(
    baseurl,
    loadGradeLevelsBegin,
    loadGradeLevelsSuccess,
    loadGradeLevelsFail
  );
};
