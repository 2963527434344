export const REGISTER_PARTICIPANT_BEGIN = "REGISTER_PARTICIPANT_BEGIN";
export const REGISTER_PARTICIPANT_SUCCESS = "REGISTER_PARTICIPANT_SUCCESS";
export const REGISTER_PARTICIPANT_FAIL = "REGISTER_PARTICIPANT_FAIL";

export const REGISTER_TEAM_BEGIN = "REGISTER_TEAM_BEGIN";
export const REGISTER_TEAM_SUCCESS = "REGISTER_TEAM_SUCCESS";
export const REGISTER_TEAM_FAIL = "REGISTER_TEAM_FAIL";

export const LOAD_BOOKS_SUCCESS = "LOAD_BOOKS_SUCESS";
export const LOAD_BOOKS_FAIL = "LOAD_BOOKS_FAIL";
export const LOAD_BOOKS_BEGIN = "LOAD_BOOKS_BEGIN";
export const LOAD_BOOKS_RESET = "LOAD_BOOKS_RESET";
//export const LOAD_BOOKS = "LOAD_BOOK;";
export const LOAD_AUTHORS_SUCCESS = "LOAD_AUTHORS_SUCESS";
export const LOAD_AUTHORS_FAIL = "LOAD_AUTHORS_FAIL";
export const LOAD_AUTHORS_BEGIN = "LOAD_AUTHORS_BEGIN";
export const LOAD_AUTHORS_RESET = "LOAD_AUTHORS_RESET";

export const LOAD_GRADES_SUCCESS = "LOAD_GRADES_SUCESS";
export const LOAD_GRADES_FAIL = "LOAD_GRADES_FAIL";
export const LOAD_GRADES_BEGIN = "LOAD_GRADES_BEGIN";

export const LOAD_BATTLE_SUCCESS = "LOAD_BATTLE_SUCESS";
export const LOAD_BATTLE_FAIL = "LOAD_BATTLE_FAIL";
export const LOAD_BATTLE_BEGIN = "LOAD_BATTLE_BEGIN";
export const LOAD_BATTLE_RESET = "LOAD_BATTLE_RESET";

export const START_BATTLE_SUCCESS = "START_BATTLE_SUCESS";
export const START_BATTLE_FAIL = "START_BATTLE_FAIL";
export const START_BATTLE_BEGIN = "START_BATTLE_BEGIN";
export const START_BATTLE_RESET = "START_BATTLE_RESET";

export const ANSWER_QUESTION_BEGIN = "ANSWER_QUESTION_BEGIN";
export const ANSWER_QUESTION_FAIL = "ANSWER_QUESTION_FAIL";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_RESET = "ANSWER_QUESTION_RESET";

export const GET_RESULTS_BEGIN = "GET_RESULTS_BEGIN";
export const GET_RESULTS_FAIL = "GET_RESULTS_FAIL";
export const GET_RESULTS_SUCCESS = "GET_RESULTS_SUCCESS";
export const GET_RESULTS_RESET = "GET_RESULTS_RESET";

export const REDIRECT = "REDIRECT";
export const APP_RESET = "APP_RESET";
