export const defaultState = {
  active: {
    participant: {
      internalId: "",
      teamName: "",
      gradeLevel: "",
    },
    battle: {
      internalId: "",
      curNdx: "",
      questions: [],
    },
  },

  gradeLevels: [
    { internalId: "middle", name: "Middle School" },
    { internalId: "elem", name: "Elementary" },
  ],
  books: [
    {
      internalId: "bk01",
      name: "How Oscar Indigo Broke the Universe (and Put it Back Together)",
      level: "elem",
      author: "auth01",
    },
    { internalId: "bk02", name: "Lucky Broken Girl", level: "elem", author: "auth02" },
    { internalId: "bk03", name: "The Doughnut Fix", level: "elem", author: "auth03" },
    { internalId: "bk04", name: "The Wolf's Boy", level: "elem", author: "auth04" },
    { internalId: "bk05", name: "Out of Left Field", level: "elem", author: "auth05" },
    {
      internalId: "bk06",
      name: "The Year Money Grew on Trees",
      level: "elem",
      author: "auth06",
    },
    { internalId: "bk07", name: "Blue Birds", level: "elem", author: "auth07" },
    { internalId: "bk08", name: "Let 'Er Buck", level: "elem", author: "auth08" },
    {
      internalId: "bk09",
      name: "The Black Book of Secrets",
      level: "elem",
      author: "auth09",
    },
    { internalId: "bk10", name: "Library on Wheels", level: "elem", author: "auth10" },
    {
      internalId: "bk11",
      name: "The Screaming Staircase",
      level: "elem",
      author: "auth11",
    },
    { internalId: "bk12", name: "The Game of Silence", level: "elem", author: "auth12" },
    {
      internalId: "bk13",
      name: "The Parker Inheritance",
      level: "elem",
      author: "auth13",
    },
    {
      internalId: "bk14",
      name: "Louisiana's Way Home",
      level: "elem",
      author: "auth14",
    },
  ],
  authors: [
    { internalId: "auth01", name: "David Teague" },
    { internalId: "auth02", name: "Ruth Behar" },
    { internalId: "auth03", name: "Jessie Janowitz" },
    { internalId: "auth04", name: "Susan Williams Beckhorn" },
    { internalId: "auth05", name: "Ellen Klages" },
    { internalId: "auth06", name: "Aaron Hawkins" },
    { internalId: "auth07", name: "Caroline Starr Rose" },
    { internalId: "auth08", name: "Vaunda Micheaux Nelson" },
    { internalId: "auth09", name: "F. E. Higgins" },
    { internalId: "auth10", name: "Sharlee Glenn" },
    { internalId: "auth11", name: "Jonathan Stroud" },
    { internalId: "auth12", name: "Louise Erdrich" },
    { internalId: "auth13", name: "Varian Johnson" },
    { internalId: "auth14", name: "Kate DiCamillo" },
  ],
  questions: [
    {
      internalId: "ques011",
      txt:
        "In which book does the same character pitch all  three games of a championship series even though that is against the rules?",
      url: "https://www.youtube.com/watch?v=krHM_8tYqYs",
      mode: "test",
    },
    {
      internalId: "ques012",
      txt:
        "In which book does a character use his phone to record his mom singing and play it for a restaurant owner?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques013",
      txt: "In which book did characters go to Rossini's restaurant to celebrate?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques014",
      txt: "In which book did trees have tentacles and pterodactyls fly in the sky?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques015",
      txt:
        "In which book does someone tell a character, â€œSheesh, you're a loser, even when you winâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques016",
      txt:
        "In which book does a character's mother pull up in a car with only one working headlight?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques017",
      txt: "In which book does a character catch a green tomato that is thrown at him?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques018",
      txt:
        "In which book is an orange house cat chased out of sand dunes by a saber-toothed cat?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques019",
      txt:
        "In which book does a character's father show up in his driveway in agleaming, white sports car?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0110",
      txt:
        "In which book does someone give a character a baseball glove and then show him how to take care of it with oil?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques021",
      txt: "In which book does the main character's father bring her a typewriter?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques022",
      txt:
        "In which book does a character have to spell the word â€œcommiserateâ€ correctly to be promoted to a different class?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques023",
      txt:
        "In which book does a character get angry because he must eat dark chicken meat instead of white meat?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques024",
      txt: "In which book does a character sing, â€œThese boots were made for walkingâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques025",
      txt:
        "In which book does a character have hammocks in the living room instead of sofas?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques026",
      txt:
        "In which book was the main character put on a strict diet so they wouldn't gain weight?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques027",
      txt: "In which book do characters use a siren to help cheer up another character?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques028",
      txt:
        "In which book does a character keep a flashlight in bed to help them feel brave?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques029",
      txt: "In which book was a character upset when her hair was cut short?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0210",
      txt: "In which book did a character have to use a bedpan?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques031",
      txt:
        "In which book does a character ride to town in a baby buggy pulled by a bike?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques032",
      txt:
        "In which book is the only access to a character's attic bedroom via a rope ladder?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques033",
      txt:
        "In which book do characters decide to buy their dairy productsfrom Stinky Cheese Farm?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques034",
      txt:
        "In which book did characters have to come up with a project since they couldn't start school until January?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques035",
      txt:
        "In which book did a character have chickens that spent their days outside eating plants and laid eggs with orange yolks?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques036",
      txt: "In which book did a character get the nickname â€œSlickâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques037",
      txt:
        "In which book did a character climb a tree and was afraid to climb back down?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques038",
      txt:
        "In which book does a character name the mountain they live on â€œTerror Mountainâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques039",
      txt:
        "In which book does a character have two imaginary friends called â€œTawatty Tawattyand  Dabu Dabuâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0310",
      txt: "In which book does a character learn to skate using a lawn chair?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques041",
      txt: "In which book was a character's belch loud enough to be heard outside?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques042.",
      txt:
        "In which bookdoes a character say, â€œShe just wants to use her teeth now that she has themâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques043",
      txt:
        "In which book does sleep come to a character after drinking a bitter tasting liquid?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques044",
      txt:
        "In which book does a character say someone isa â€œstinking pile of hyena dungâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques045",
      txt:
        "In which book does a character say, â€œYou treat me as dirt!  As less than dirt!â€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques046",
      txt:
        "In which book does a character say, â€œI did not want to live, but I did not have the courage to dieâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques047",
      txt:
        "In which book did a character have a pouch of sacred things that included bloodstone powder, a babytooth, a stone shell, and a rainbow stone?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques048",
      txt: "In which book did a character walk into the night naked and never come back?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques049",
      txt: "In which book was a character's main job to gather fuel?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0410",
      txt: "In which book did a character paint a horse herd on a wall?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques051",
      txt: "In which book was a character surprised to have a male teacher?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques052",
      txt:
        "In which book were charactersgiventhe assignment to watch a Walt Disney program on TV?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques053",
      txt:
        "In which book did a character smash plates because colored people had eaten off them?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques054",
      txt:
        "In which book did a character use letters, photographs, newspaper clippings, copies of articles, and souvenirs for a school project?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques055",
      txt:
        "In which book did a character wake up at 4:30  in the morning and go outside in her PJs to watch a little blip move across the sky?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques056",
      txt:
        "In which book does Section III, Paragraph G of the rulebooksay, â€œGirls are not eligibleâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques057",
      txt:
        "In which book does a character strike out Willie Mays and get a ball with an autograph on it?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques058",
      txt:
        "In which book does a character yelland throw a dozen eggs at a tree after receiving a letter containing bad news?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques059",
      txt:
        "In which book does the main character receive an A+ for her research project?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0510",
      txt:
        "In which book does a character get a weird plastic saucer called a Frisbee for her birthday?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques061",
      txt:
        "In which book does a character have a room decorated with Michael Jackson posters?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques062",
      txt:
        "In which book does a character use the excuse of having a big test to get their mom to cook fish on another night?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques063",
      txt: "In which book does a character walk off with another character's confidence?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques064",
      txt:
        "In which book does a character go to the store for bug spray and end up charging the spray, sodas, and gas to his dad?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques065",
      txt:
        "In which book did a character file a contract in the A to AR encyclopedia volume? ",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques066",
      txt:
        "In which book did children connect lots of extension cords to have radio where they were working?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques067",
      txt: "In which book did a dairy farm provide free manure for fertilizing?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques068",
      txt:
        "In which book did children stand on the back of a plow to make the deepest ditch possible?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques069",
      txt: "In which book did characters learn to siphon gas?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0610",
      txt:
        "In which book did a character say to leave the stem on the apples when they are picked?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques071",
      txt: "In which book do characters have the mark of four inked arrows?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques072",
      txt:
        "In which book is a raised hand in greeting a form of communication between two characters?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques073",
      txt: "In which book does the gift of a ribbon have to remain hidden from sight?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques074",
      txt:
        "In which book is a character expected to take care of younger children every day?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques075",
      txt:
        "In which book did a character tell people that if they need to leave, to carve where they go into the trunk of a tree; and if they are in danger, to include a cross in the carving?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques076",
      txt:
        "In which book does a character leave the village through a space in an earthen wall?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques077",
      txt: "In which book has a character's father been beheaded by newcomers?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques078",
      txt:
        "In which book is a character devastated with the thought that her uncle is dead?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques079",
      txt:
        "In which book does the captain of a ship not take people to Chesapeake as promised?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0710",
      txt: "In which book does a character take bread to a prisoner?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques081",
      txt:
        "In which book did a boy bunk with families on the Umatilla Indian Reservation?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques082",
      txt:
        "In which book did a character blow softly into the nostrils of horses so they could breathe him in?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques083",
      txt: "In which book did two characters ride back-to-back on a bronco?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques084",
      txt:
        "In which book did the second place contestant end up with more money than the valueof the first place prize? ",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques085",
      txt:
        "In which book isa character treated unfairly, but was set on showing what they were made of?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques086",
      txt: "In which book do fifteen thousand fans pack a grandstand for an event?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques087",
      txt: "In which book did a character have to pay a judge a $5fine?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques088",
      txt: "In which book do characters use a barrel for an imaginary horse? ",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques089",
      txt:
        "In which book did a character compete against a Nez Perce Indian and a white rancher?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0810",
      txt: "In which book did a character get a second chance in a competition?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques091",
      txt:
        "In which book does a character set up his business in the milliner's old shop?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques092",
      txt:
        "In which book did a character make a mincemeat pie that contained a poison rat?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques093",
      txt:
        "In which book was there a quite valuable book with the 13thline of text reversed?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques094",
      txt: "In which book did boys come to a shop to see a frog?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques095",
      txt:
        "In which book did a character read from a book, even though he had been forbidden to do so?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques096",
      txt: "In which book did a character pretend to be a doctor?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques097",
      txt:
        "In which book did villagers go through a character's house after his death, taking what they wanted?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques098",
      txt:
        "In which book is the act of betrayal the luckiest piece of luck a character has ever had?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques099",
      txt: "In which book is a character's wealth made off of the backs of the poor?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques0910",
      txt:
        "In which book does a character give the advice, â€œDo not jest about the devil, you never know when he is listeningâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques101",
      txt:
        "In which book did a character think she would not be a good teacher because she lacked patience?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques102",
      txt:
        "In which book was it unusual for farm girls to attend school beyond the eighth grade?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques103",
      txt: "In which book did a character have an application rejected by Melvil Dewey?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques104",
      txt:
        "In which book was a character one of the first women to hold a state office in Vermont?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques105",
      txt:
        "In which book did a character's family think it was unwise for a character to leave Vermont and go by herself to rural Maryland?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques106",
      txt: "In which book did a character have a book wrapped carefully in her apron?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques107",
      txt: "In which book did a character start a storytelling hour in remote areas?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques108",
      txt:
        "In which book did the board of trustees give their approval to a project even though it sounded like a crazy plan?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques109",
      txt:
        "In which book did a character decide not to become a nurse because the sight of blood made her queasy?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques1010",
      txt:
        "In which book does a character receive a postcard that complimented them on their work?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques111",
      txt: "In which book did characters try to figure out a message written in Latin?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques112",
      txt:
        "In which book did characters discover that a necklace was really a locket that opened?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques113",
      txt: "In which book were children much more likelyto see ghosts than adults?P. 63",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques114",
      txt: "In which book did characters find a secret opening behind a shutter?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques115",
      txt:
        "In which book is a character's father a porter in a railway station of atown north of England?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques116",
      txt:
        "In which book does a character think that an article on page 6 of the â€œTimesâ€ is the best bit of publicity they have ever had?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques117",
      txt:
        "In which book does a character have prizes such as bones and blood-stained playing cards?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques118",
      txt:
        "In which book do the characters forget to bring iron chains, which are usually standard equipment, to a job site?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques119",
      txt: "In which book do two characters fall from the window of a burning house?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques1110",
      txt:
        "In which book does the inscriptionin a locket refer to Act II, Scene 2, lines 115-188  of the play â€œHamletâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques121",
      txt:
        "In which book do some characters put rocks inside their snowballs so they will hurt more when they hit people?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques122",
      txt:
        "In which book did characters decide to send runners out to discover who had broken the promise to be peaceful?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques123",
      txt: "In which book did a character's grandmother make her a rabbit-skin blanket?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques124",
      txt:
        "In which book did characters use a pinch of tobacco to say thank you to the spirits?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques125",
      txt:
        "In which book did a character need to have a finger cut off because it had frostbite and had turned black?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques126",
      txt:
        "In which book are prizes given to children who stay quiet while the adults talk?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques127.",
      txt:
        "In which bookdoes a family adopt a baby boy brought to their camp by the Raggedy Ones?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques128",
      txt:
        "In which book is a character not allowed to help with the ricing because she and her cousin had ruined the rice plants by harvesting too early?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques129",
      txt:
        "In which book is a character famous for keeping lumps of maple sugar inside her dress pockets?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques1210",
      txt:
        "In which book does a character work so that she will have enough credit to buy material for a new dress for her sister?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques131",
      txt: "In which book does a character find a letter hidden in a puzzle book?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques132",
      txt:
        "In which book does a character make a schedule to help her friend avoid the boy who bullies him?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques133",
      txt:
        "In which book do characters say they are doing a summer research project when they are really trying to find a fortune in money?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques134",
      txt:
        "In which book did a character use a computer at the library to do research and check emails because her home computer had crashed?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques135",
      txt:
        "In which book did characters make a deal to each read the books they had checked out from the library and then trade them?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques136",
      txt:
        "In which book did a character find the message â€œFind the path. Solve the puzzleâ€ written on an envelope?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques137",
      txt: "In which book was a character forced to resignas city manager?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques138",
      txt: "In which book did characterswatch a secret tennis game?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques139",
      txt:
        "In which book did a character have a bracelet that she had gotten from her grandmother?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques1310",
      txt:
        "In which book does a character leave a fifteen-cent tip hoping a certain someone would notice his generosity?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques141",
      txt:
        "In which book did a character wake her granddaughter up saying, â€œThe day of reckoning has arrivedâ€?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques142",
      txt:
        "In which book did a character think it was good to know where the North Star was so she would notbe lost in the world?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques143",
      txt: "In which book was a character named after a five-and-dime store?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques144",
      txt:
        "In which book do characters think that speaking of a curse only intensifies it?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques145",
      txt: "In which book does a character commune with an alligator?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques146",
      txt: "In which book was a character tired of leaving places?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques147",
      txt: "In which book does a crow land on a boy's shoulder when he whistles?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques148",
      txt: "In which book does a motel owner almost always have her hair in curlers?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques149",
      txt:
        "In which book does a character make up a fictitious address to get out of paying a dental bill?",
      url: "",
      mode: "test",
    },
    {
      internalId: "ques1410",
      txt:
        "In which book would you find a character that lives in a house as pink as cotton candy?",
      url: "",
      mode: "test",
    },
  ],
  correctAnswers: [
    { internalId: "ans011", question: "ques011", book: "bk01", notes: "P. 181" },
    { internalId: "ans012", question: "ques012", book: "bk01", notes: "P. 174" },
    { internalId: "ans013", question: "ques013", book: "bk01", notes: "P. 53" },
    { internalId: "ans014", question: "ques014", book: "bk01", notes: "P. 103" },
    { internalId: "ans015", question: "ques015", book: "bk01", notes: "p. 47" },
    { internalId: "ans016", question: "ques016", book: "bk01", notes: "P. 49" },
    { internalId: "ans017", question: "ques017", book: "bk01", notes: "P. 73" },
    { internalId: "ans018", question: "ques018", book: "bk01", notes: "P. 107" },
    { internalId: "ans019", question: "ques019", book: "bk01", notes: "P. 129" },
    { internalId: "ans0110", question: "ques0110", book: "bk01", notes: "P. 136-137" },
    { internalId: "ans021", question: "ques021", book: "bk02", notes: "P. 157" },
    { internalId: "ans022", question: "ques022", book: "bk02", notes: "P. 6" },
    { internalId: "ans023", question: "ques023", book: "bk02", notes: "P. 19-20" },
    { internalId: "ans024", question: "ques024", book: "bk02", notes: "p. 36" },
    { internalId: "ans025", question: "ques025", book: "bk02", notes: "P. 211" },
    { internalId: "ans026", question: "ques026", book: "bk02", notes: "P. 103" },
    { internalId: "ans027", question: "ques027", book: "bk02", notes: "P. 58" },
    { internalId: "ans028", question: "ques028", book: "bk02", notes: "P. 88" },
    { internalId: "ans029", question: "ques029", book: "bk02", notes: "P. 99" },
    { internalId: "ans0210", question: "ques0210", book: "bk02", notes: "P. 61" },
    { internalId: "ans031", question: "ques031", book: "bk03", notes: "P. 97" },
    { internalId: "ans032", question: "ques032", book: "bk03", notes: "P. 33" },
    { internalId: "ans033", question: "ques033", book: "bk03", notes: "P. 171" },
    { internalId: "ans034", question: "ques034", book: "bk03", notes: "P. 57" },
    { internalId: "ans035", question: "ques035", book: "bk03", notes: "P. 65" },
    { internalId: "ans036", question: "ques036", book: "bk03", notes: "p. 131" },
    { internalId: "ans037", question: "ques037", book: "bk03", notes: "P. 251" },
    { internalId: "ans038", question: "ques038", book: "bk03", notes: "p. 55" },
    { internalId: "ans039", question: "ques039", book: "bk03", notes: "p. 63" },
    { internalId: "ans0310", question: "ques0310", book: "bk03", notes: "P. 119" },
    { internalId: "ans041", question: "ques041", book: "bk04", notes: "P. 57" },
    { internalId: "ans042.", question: "ques042.", book: "bk04", notes: "" },
    { internalId: "ans043", question: "ques043", book: "bk04", notes: "P. 64" },
    { internalId: "ans044", question: "ques044", book: "bk04", notes: "p. 70" },
    { internalId: "ans045", question: "ques045", book: "bk04", notes: "p. 107" },
    { internalId: "ans046", question: "ques046", book: "bk04", notes: "p. 126" },
    { internalId: "ans047", question: "ques047", book: "bk04", notes: "(Chapter 21)" },
    { internalId: "ans048", question: "ques048", book: "bk04", notes: "(Chapter 9)" },
    { internalId: "ans049", question: "ques049", book: "bk04", notes: "(Chapter 11)" },
    { internalId: "ans0410", question: "ques0410", book: "bk04", notes: "(Chapter 29)" },
    { internalId: "ans051", question: "ques051", book: "bk05", notes: "P. 25" },
    { internalId: "ans052", question: "ques052", book: "bk05", notes: "" },
    { internalId: "ans053", question: "ques053", book: "bk05", notes: "" },
    { internalId: "ans054", question: "ques054", book: "bk05", notes: "P. 230" },
    { internalId: "ans055", question: "ques055", book: "bk05", notes: "P. 122" },
    { internalId: "ans056", question: "ques056", book: "bk05", notes: "p. 64" },
    { internalId: "ans057", question: "ques057", book: "bk05", notes: "P. 267-268" },
    { internalId: "ans058", question: "ques058", book: "bk05", notes: "P. 82-83" },
    { internalId: "ans059", question: "ques059", book: "bk05", notes: "P. 239" },
    { internalId: "ans0510", question: "ques0510", book: "bk05", notes: "p. 49" },
    { internalId: "ans061", question: "ques061", book: "bk06", notes: "P. 49" },
    { internalId: "ans062", question: "ques062", book: "bk06", notes: "P. 104" },
    { internalId: "ans063", question: "ques063", book: "bk06", notes: "P. 151" },
    { internalId: "ans064", question: "ques064", book: "bk06", notes: "P. 158" },
    { internalId: "ans065", question: "ques065", book: "bk06", notes: "" },
    { internalId: "ans066", question: "ques066", book: "bk06", notes: "" },
    { internalId: "ans067", question: "ques067", book: "bk06", notes: "" },
    { internalId: "ans068", question: "ques068", book: "bk06", notes: "" },
    { internalId: "ans069", question: "ques069", book: "bk06", notes: "P. 93" },
    { internalId: "ans0610", question: "ques0610", book: "bk06", notes: "P. 211" },
    { internalId: "ans071", question: "ques071", book: "bk07", notes: "P. 31" },
    { internalId: "ans072", question: "ques072", book: "bk07", notes: "P. 91" },
    { internalId: "ans073", question: "ques073", book: "bk07", notes: "P. 118" },
    { internalId: "ans074", question: "ques074", book: "bk07", notes: "P. 73" },
    { internalId: "ans075", question: "ques075", book: "bk07", notes: "P. 241" },
    { internalId: "ans076", question: "ques076", book: "bk07", notes: "P. 200" },
    { internalId: "ans077", question: "ques077", book: "bk07", notes: "P. 19" },
    { internalId: "ans078", question: "ques078", book: "bk07", notes: "P. 114" },
    { internalId: "ans079", question: "ques079", book: "bk07", notes: "" },
    { internalId: "ans0710", question: "ques0710", book: "bk07", notes: "" },
    { internalId: "ans081", question: "ques081", book: "bk08", notes: "" },
    { internalId: "ans082", question: "ques082", book: "bk08", notes: "" },
    { internalId: "ans083", question: "ques083", book: "bk08", notes: "" },
    { internalId: "ans084", question: "ques084", book: "bk08", notes: "" },
    { internalId: "ans085", question: "ques085", book: "bk08", notes: "" },
    { internalId: "ans086", question: "ques086", book: "bk08", notes: "" },
    { internalId: "ans087", question: "ques087", book: "bk08", notes: "" },
    { internalId: "ans088", question: "ques088", book: "bk08", notes: "" },
    { internalId: "ans089", question: "ques089", book: "bk08", notes: "" },
    { internalId: "ans0810", question: "ques0810", book: "bk08", notes: "" },
    { internalId: "ans091", question: "ques091", book: "bk09", notes: "P. 12 and 93" },
    { internalId: "ans092", question: "ques092", book: "bk09", notes: "P. 100-101" },
    { internalId: "ans093", question: "ques093", book: "bk09", notes: "P. 173" },
    { internalId: "ans094", question: "ques094", book: "bk09", notes: "P. 75" },
    { internalId: "ans095", question: "ques095", book: "bk09", notes: "P. 149" },
    { internalId: "ans096", question: "ques096", book: "bk09", notes: "P. 132" },
    { internalId: "ans097", question: "ques097", book: "bk09", notes: "P. 225" },
    { internalId: "ans098", question: "ques098", book: "bk09", notes: "P. 5" },
    { internalId: "ans099", question: "ques099", book: "bk09", notes: "P. 57" },
    { internalId: "ans0910", question: "ques0910", book: "bk09", notes: "p. 124" },
    { internalId: "ans101", question: "ques101", book: "bk10", notes: "P. 7" },
    { internalId: "ans102", question: "ques102", book: "bk10", notes: "P. 3" },
    { internalId: "ans103", question: "ques103", book: "bk10", notes: "P. 10" },
    { internalId: "ans104", question: "ques104", book: "bk10", notes: "P. 12" },
    { internalId: "ans105", question: "ques105", book: "bk10", notes: "P. 14" },
    { internalId: "ans106", question: "ques106", book: "bk10", notes: "P. 18" },
    { internalId: "ans107", question: "ques107", book: "bk10", notes: "P. 19" },
    { internalId: "ans108", question: "ques108", book: "bk10", notes: "P. 23" },
    { internalId: "ans109", question: "ques109", book: "bk10", notes: "P. 7" },
    { internalId: "ans1010", question: "ques1010", book: "bk10", notes: "P. 13" },
    { internalId: "ans111", question: "ques111", book: "bk11", notes: "P. 162" },
    { internalId: "ans112", question: "ques112", book: "bk11", notes: "P. 214" },
    { internalId: "ans113", question: "ques113", book: "bk11", notes: "" },
    { internalId: "ans114", question: "ques114", book: "bk11", notes: "P. 306" },
    { internalId: "ans115", question: "ques115", book: "bk11", notes: "P. 57" },
    { internalId: "ans116", question: "ques116", book: "bk11", notes: "P. 184" },
    { internalId: "ans117", question: "ques117", book: "bk11", notes: "P. 206" },
    { internalId: "ans118", question: "ques118", book: "bk11", notes: "P. 34" },
    { internalId: "ans119", question: "ques119", book: "bk11", notes: "p. 32" },
    { internalId: "ans1110", question: "ques1110", book: "bk11", notes: "P. 351" },
    { internalId: "ans121", question: "ques121", book: "bk12", notes: "P. 167" },
    { internalId: "ans122", question: "ques122", book: "bk12", notes: "P. 23" },
    { internalId: "ans123", question: "ques123", book: "bk12", notes: "P. 99" },
    { internalId: "ans124", question: "ques124", book: "bk12", notes: "P. 101" },
    { internalId: "ans125", question: "ques125", book: "bk12", notes: "P. 181" },
    { internalId: "ans126", question: "ques126", book: "bk12", notes: "P. 17-18" },
    { internalId: "ans127.", question: "ques127.", book: "bk12", notes: "" },
    { internalId: "ans128", question: "ques128", book: "bk12", notes: "P. 81" },
    { internalId: "ans129", question: "ques129", book: "bk12", notes: "P. 51" },
    { internalId: "ans1210", question: "ques1210", book: "bk12", notes: "P. 130" },
    { internalId: "ans131", question: "ques131", book: "bk13", notes: "P. 28" },
    { internalId: "ans132", question: "ques132", book: "bk13", notes: "P. 108" },
    { internalId: "ans133", question: "ques133", book: "bk13", notes: "P. 30, 42, 202" },
    { internalId: "ans134", question: "ques134", book: "bk13", notes: "P. 18" },
    { internalId: "ans135", question: "ques135", book: "bk13", notes: "P. 20" },
    { internalId: "ans136", question: "ques136", book: "bk13", notes: "P. 32" },
    { internalId: "ans137", question: "ques137", book: "bk13", notes: "P. 7" },
    { internalId: "ans138", question: "ques138", book: "bk13", notes: "P. 195" },
    { internalId: "ans139", question: "ques139", book: "bk13", notes: "P. 5" },
    { internalId: "ans1310", question: "ques1310", book: "bk13", notes: "P. 165" },
    { internalId: "ans141", question: "ques141", book: "bk14", notes: "p. 33" },
    { internalId: "ans142", question: "ques142", book: "bk14", notes: "P. 216" },
    { internalId: "ans143", question: "ques143", book: "bk14", notes: "P. 122" },
    { internalId: "ans144", question: "ques144", book: "bk14", notes: "P. 94" },
    { internalId: "ans145", question: "ques145", book: "bk14", notes: "P. 127" },
    { internalId: "ans146", question: "ques146", book: "bk14", notes: "P. 173" },
    { internalId: "ans147", question: "ques147", book: "bk14", notes: "P. 92" },
    { internalId: "ans148", question: "ques148", book: "bk14", notes: "P. 57, 125" },
    { internalId: "ans149", question: "ques149", book: "bk14", notes: "P. 51" },
    { internalId: "ans1410", question: "ques1410", book: "bk14", notes: "P. 108" },
  ],
  registrationQuestions: [
    {
      internalId: "pq1",
      label: "First Name",
      tag: "firstName",
      level: "all",
      type: "participant",
    },
    {
      internalId: "pq2",
      label: "Last Name",
      tag: "lastName",
      level: "all",
      type: "participant",
    },
    { internalId: "pq3", label: "School Name", tag: "school", level: "all", type: "all" },
    {
      internalId: "pq4",
      label: "Teacher Name",
      tag: "teacher",
      level: "all",
      type: "all",
    },
    {
      internalId: "pq5",
      label: "Middle Only",
      tag: "firstName",
      level: "middle",
      type: "participant",
    },
  ],
  participants: [
    {
      internalId: "abc",
      firstName: "Bob",
      lastName: "smith",
      school: "EdS",
      teacher: "Ms. Cole",
      level: "elem",
    },
  ],
  teams: [{ internalId: "t123", school: "SouthWest Elementary", teacher: "Ms. Smith" }],
  registrations: [
    { internalId: "abc", type: "team", referenceId: "t123" },
    { internalId: "def", type: "participant", referenceId: "abc" },
  ],
  battles: [
    {
      internalId: "btl1",
      level: "elem",
      name: "Elementary Battle #1",
      questions: ["ques041", "ques012", "ques023", "ques031", "ques044", "quest056"],
    },
  ],
  battleRuns: [
    {
      internalId: "btlRn1",
      participant: "",
      team: "",
      battle: "btl1",
      starttime: Date.now(),
      endtime: Date.now(),
    },
  ],
  //{ id:"uuid", participant:"", battle:"", starttime:"", endtime:""}
  battleAnswers: [
    {
      internalID: "ba1",
      battleRun: "btlRn1",
      question: "ques001",
      answer: { book: "", author: "" },
    },
  ],
  //{ id:"uuid", participant:"", battleRun:"", battleQuestion:"", book:"", author:""}
};
