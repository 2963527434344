const resetDefaultReturn = (state /*,action*/) => {
  return {
    ...state,
    loading: true,
    error: null,
    items: [],
  };
};

const beginDefaultReturn = (state /*,action*/) => {
  return {
    ...state,
    loading: true,
    error: null,
  };
};

const successDefaultReturn = (state, action) => {
  return { ...state, loading: false, items: action.payload };
};

const failDefaultReturn = (state, action) => {
  return { ...state, loading: false, error: action.payload.error, items: [] };
};

const defaultReturn = (state /*,action*/) => {
  return state;
};

const reducerReturns = {
  resetDefaultReturn,
  beginDefaultReturn,
  successDefaultReturn,
  failDefaultReturn,
  defaultReturn,
};

export default reducerReturns;
