import fetch from "cross-fetch";

import {
  LOAD_BOOKS_BEGIN,
  LOAD_BOOKS_FAIL,
  LOAD_BOOKS_SUCCESS,
  LOAD_BOOKS_RESET,
} from "./actionTypes";
import { handleResponse, handleError, makeURL } from "../../api/apiUtils";
import { getData } from "./actionBase";

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
export const loadBooksSuccess = (books) => {
  return { type: LOAD_BOOKS_SUCCESS, payload: books };
};
export const loadBooksFail = (error) => {
  return { type: LOAD_BOOKS_FAIL, payload: error };
};
export const loadBooksBegin = () => {
  return { type: LOAD_BOOKS_BEGIN };
};
export const resetBooks = () => {
  return { type: LOAD_BOOKS_RESET };
};

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

function shouldRefreshBooks(state) {
  var bRefresh = false;
  if (!state.books) {
    bRefresh = true;
  } else if (state.books.items.length === 0) {
    bRefresh = true;
  }

  return bRefresh;
}

export const getBookList = (simple) => {
  const baseurl = simple
    ? makeURL("entities", "books")
    : makeURL("entities", "books", "allfields");
  return getData(baseurl, loadBooksBegin, loadBooksSuccess, loadBooksFail);
};

export const fetchBooksIfNeeded = (simple = true) => {
  return (dispatch, getState) => {
    if (shouldRefreshBooks(getState())) {
      return dispatch(getBookList(simple));
    }
    return; // fetch("/books").then(handleError);
  };
};

export const fetchBooks = (simple = true, refresh = false) => {
  return (dispatch) => {
    if (refresh) {
      dispatch(resetBooks());
    }
    return dispatch(getBookList(simple));
  };
};
