import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import NMBoBNavBar from "./shared/Navigator";
import PageNotFound from "./PageNotFound";
import BooksPage from "./Books/BooksPage";
import BattleQuestion from "./Battle/BattleQuestion";
import RegistrationPg from "./Registration";
import BattleStartPage from "./Battle/BattleStartPage";
import { AppContext } from "../context/context";
import BattleResultsPage from "./Battle/BattleResults";

//import "bootswatch/dist/slate/bootstrap.min.css"; // Added this :boom:
//import './App.css';
//import { makeImgUrl } from "../api/apiUtils";
//var appStyle = { color: "black", backgroundImage: makeImgUrl("Bob-bg.jpg"), };

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  let pr = {
    dispName: "test name",
    question: {
      url: "https://youtu.be/oAIAm6BF0fs",
      qtxt: "question text here",
    },
  };
  return (
    <div className="container page-header ">
      <NMBoBNavBar />
      <div className="jumbotron " align="center">
        <h1>New Mexico Battle of the Books</h1>
        <h2>Online edition</h2>
      </div>
      <AppContext.Provider values={{ isAuthenticated, userHasAuthenticated }}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/books" component={BooksPage} />
          <Route path="/register" component={RegistrationPg} />
          <Route path="/battle/start" exact component={BattleStartPage} />
          <Route path="/battle/question" exact component={BattleQuestion} />
          <Route path="/battle/results" exact component={BattleResultsPage} />
          <Route path="/test/battle" exact component={BattleStartPage} />
          <Route
            path="/test/battle/question"
            exact
            render={() => <BattleQuestion {...pr} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </AppContext.Provider>
    </div>
  );
}

export default App;
