import {
  START_BATTLE_BEGIN,
  START_BATTLE_SUCCESS,
  START_BATTLE_RESET,
  START_BATTLE_FAIL,
} from "../actions/actionTypes";
import { reducerinitialState } from "../storeDefaults";
import reducerReturns from "./reducerUtils";

var debugThisFile = false;
export default function battleReducer(state = reducerinitialState, action) {
  switch (action.type) {
    case START_BATTLE_RESET:
      if (debugThisFile) debugger;
      return reducerReturns.resetDefaultReturn(state);

    case START_BATTLE_BEGIN:
      if (debugThisFile) debugger;
      return reducerReturns.beginDefaultReturn(state);

    case START_BATTLE_SUCCESS:
      if (debugThisFile) debugger;
      return { ...state, loading: false, item: action.payload };

    case START_BATTLE_FAIL:
      if (debugThisFile) debugger;
      return { ...state, loading: false, error: action.payload, items: [] };
    //    return reducerReturns.failDefaultReturn(state, action);

    default:
      return reducerReturns.defaultReturn(state);
  }
}
