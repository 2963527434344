import {
  LOAD_BOOKS_BEGIN,
  LOAD_BOOKS_SUCCESS,
  LOAD_BOOKS_FAIL,
  LOAD_BOOKS_RESET,
} from "../actions/actionTypes";
import { reducerinitialState } from "../storeDefaults";

export default function bookReducer(state = reducerinitialState, action) {
  switch (action.type) {
    case LOAD_BOOKS_RESET:
      return {
        ...state,
        loading: true,
        error: null,
        items: [],
      };

    case LOAD_BOOKS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOAD_BOOKS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case LOAD_BOOKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      };

    default:
      return state;
  }
}
