import fetch from "cross-fetch";

var debugThisFile = false;
/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
export const apiResponseProcess_Success = (dispatch, successFunc) => {
  (json, dispatch, successFunc) => {
    if (dispatch && successFunc) {
      dispatch(successFunc(json.data));
    }
    return json.data();
  };
};

export const apiResponseProcess_HandleError = (dispatch, failFunc) => {
  return (err) => {
    dispatch(failFunc(err));
  };
};

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
export const getData = (url, beginFunc, successFunc, failFunc) =>
  getDataParam(url, null, beginFunc, successFunc, failFunc);

export const getDataParam = (url, body, beginFunc, successFunc, failFunc) => {
  return (dispatch) => {
    var params = genParams(body);
    dispatch(beginFunc());
    return fetch(url, params)
      .then((resp) => {
        if (!resp.ok) {
          throw Error(resp.statusText);
        }
        return resp;
      })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        if (dispatch && successFunc) {
          dispatch(successFunc(json.data));
        }
        return json.data;
      })
      .catch((err) => {
        dispatch(failFunc(err));
      });
  };
};

// /// // // /// // // // // // // //
const genParams = (data = null, method = "GET", headers = null) => {
  var params = { method: method };
  if (data) {
    params.body = JSON.stringify(data);
    params.headers = { "Content-Type": "application/json" };
  }
  if (headers) {
    if (!params.headers) {
      params.headers = {};
    }

    if (Array.isArray(headers)) {
      headers.forEach((hdr) => {
        params.headers[hdr.param] = hdr.val;
      });
    } else if (typeof headers === "object") {
      params.headers = { ...headers };
    }
  }
  return params;
};

// /// // // /// // // // // // // //
export const postData = (url, data, beginFunc, successFunc, failFunc) => {
  return (dispatch) => {
    if (debugThisFile) debugger;

    var params = genParams(data, "POST");
    dispatch(beginFunc());
    return fetch(url, params)
      .then((resp) => {
        if (debugThisFile) debugger;

        console.log("O AM HERE");
        console.log(resp);
        //console.log(resp.json());

        if (!resp.ok) {
          throw Error(resp.statusText);
        }
        return resp;
      })
      .then((resp) => {
        if (debugThisFile) debugger;

        console.log("IO AM HERE");
        return resp.json();
      })
      .then((json) => {
        if (debugThisFile) debugger;

        console.log("IOIU AM HERE");
        if (dispatch) {
          if (json.status && json.status.code == 0) {
            if (successFunc) {
              dispatch(successFunc(json));
            }
          } else if (failFunc) {
            dispatch(failFunc(json));
          }
        }
        return json.data;
      })
      .catch((err) => {
        if (debugThisFile) debugger;

        console.log("OUCH AM HERE");
        dispatch(failFunc(err));
      });
  };
};
