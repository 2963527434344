import React from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, Redirect } from "react-router-dom";

import { quizSupportActions } from "../../store/actions/storeActions";
import BattleQuestion from "./BattleQuestion";

const debugThisFile = false;
const getQuestion = (battleId, questionNdx, teamId) => {};

class BattleStartPageTpl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      setValidated: (flg) => this.setState({ ...this.state, validated: flg }),
      questionNdx: -1,
      inBattle: false,
    };
  }

  componentDidMount() {
    //var { battleId, questionNdx, teamId } = this.props.location.state;
    //this.setState({ ...this.state, battleId, questionNdx, teamId });
    this.props.verifyBooksAuthorsLoaded();
  }

  inBattle = () => this.state.inBattle;

  createNewBattle = (event) => {
    this.props.createBattleRun(this.props.participant);
  };

  startBattle = (event) => {
    if (debugThisFile) debugger;
    this.setState({ ...this.state, questionNdx: -1, inBattle: true });
  };

  startLink = (linkState) => {
    if (this.props.battleRun) {
      linkState.runId = this.props.battleRun;
      console.log("OFF WE GO -- ", this.props.battleRun);

      return (
        <div>
          It is ready.
          <Link
            to={{ pathname: "/battle/question", quiz: { ...linkState, questionNdx: 0 } }}
            className="btn btn-dark btn-sm"
          >
            Click to start your quiz
          </Link>
        </div>
      );
    } else {
      return (
        <div>
          Each quiz is randomly generated from questions for the books at your level
          <br />
          <Button variant="primary" type="button" onClick={this.createNewBattle}>
            {" "}
            Create a new battle{" "}
          </Button>
        </div>
      );
    }
  };

  kickoff = () => {
    var props = {
      participant: this.props.participant,
      battle: this.props.battleId,
      team: this.props.teamId,
    };

    console.log(props);
    if (debugThisFile) debugger;

    return (
      <div>
        <h2>Welcome {props.participant.firstName}!</h2>
        <Form noValidate validated={this.state.validated} onSubmit={this.startBattle}>
          {" "}
          {this.startLink(props)}{" "}
        </Form>
      </div>
    );
  };

  render() {
    if (debugThisFile) debugger;

    if (!this.props.participant || !this.props.participant._id) {
      if (debugThisFile) debugger;
      console.log("BAD REDIRECTING HOME");

      return <Redirect to="/register" />;
      //} else if (this.inBattle()) {
      //&& this.props.question.questionId != "") {
      /*var qProp = {
        dispName: "test name",
        question: { url: "https://youtu.be/oAIAm6BF0fs", qtxt: "question text here" },
      };
      var zProp = {
        dispName: "TEST test name",
        question: {
          url: "https://youtu.be/eVTXPUF4Oz4",
          qtxt: "question text here",
        },
      };

      var x = this.props,
        y = this.state;
      if (debugThisFile) debugger;

      console.log("START REDIRECTING NOW", zprop);
      
      //if (this.inBattle()) {
      return <Redirect to={{ pathname: "/battle/question", state: { ...zProp } }} />;
      */
    } else {
      if (debugThisFile) debugger;

      return this.kickoff();
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  if (debugThisFile) debugger;

  var participant = ownProps.location.state
    ? ownProps.location.state.participant
    : state.participant.item;

  var battleRun = state.battleRuns.item._id;

  return {
    participant,
    teamId: participant._id,
    battleId: participant.battleId,
    battleRun,
    name: participant.firstName,
    question: {
      questionId: "",
    },
    quiz: { ...state.quiz, teamId: participant._id },
  };
};

const mapDispatch = (dispatch) => {
  return {
    verifyBooksAuthorsLoaded: () =>
      dispatch(quizSupportActions.verifyBooksAuthorsLoaded(true)),
    loadQuestion: (teamId, battleId, questionNdx) =>
      dispatch(quizSupportActions.getBattleQuestion(teamId, battleId, questionNdx)),
    createBattleRun: (participantObj) =>
      dispatch(quizSupportActions.createBattleRun(participantObj)),
  };
};

const BattleStartPage = connect(mapStateToProps, mapDispatch)(BattleStartPageTpl);

export default BattleStartPage;
