import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import DisplayQuestion from "./DisplayQuestion";
import DisplayAnswerSet from "./DisplayAnswerSet";
import { quizSupportActions } from "../../store/actions/storeActions";

var debugThisFile = false;
class BattleQuestion extends React.Component {
  constructor(props) {
    if (debugThisFile) debugger;

    super(props);
    this.state = {
      book: "",
      author: "",
      questionReady: false,
      validated: false,
      redirect: "",
    };
  }
  setValidated = (flg) => {
    this.setState({ ...this.state, validated: flg });
  };

  componentDidMount() {
    if (debugThisFile) debugger;

    var team, battle, ndx;
    if (this.props.quiz) {
      team = this.props.quiz.team;
      battle = this.props.quiz.battle;
      ndx = this.props.quiz.questionNdx;

      if (team != "") {
        this.props.loadQuestion(team, battle, ndx);
      }
    }
  }

  answerQuestion = (event) => {
    if (debugThisFile) debugger;

    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setValidated(true);
    } else {
      const curState = { ...this.state };

      console.log(this.props);
      console.log(this.props.battleQuiz.question);
      console.log("-- - - - - - - -  - - - - - - - - - ");

      this.props.submitAnswer(
        this.props.teamId,
        this.props.battleQuiz.battle,
        this.props.runId,
        this.props.battleQuiz.question.id,
        this.props.questionNdx,
        curState.book,
        curState.author
      );
      this.setValidated(false);
    }
  };

  answerChangeEvent = (event) => {
    if (debugThisFile) debugger;

    if (event.target.id == "bookGuessCtrl") {
      this.setState({ book: event.target.value });
    }
    if (event.target.id == "authorGuessCtrl") {
      this.setState({ author: event.target.value });
    }
  };

  loading = () => {
    return (
      <Alert key="loading" variant="success">
        Loading{this.props.loadtail}
      </Alert>
    );
  };

  goDone = () => {
    this.setState({ ...this.state, redirect: "/battle/results" });
  };
  render() {
    if (debugThisFile) debugger;

    /*
    if (this.state.redirect != "") {
      if (debugThisFile) debugger;

      return <Redirect to={this.state.redirect} />;
    }
    */

    if (this.props.quizComplete) {
      if (debugThisFile) debugger;

      //    return <Link to="/battle/results">Go To The Results</Link>;

      //<Button variant="primary" onClick={this.goDone}> Go to the Results </Button>

      return <Redirect to="/battle/results" />;
    } else if (!this.props.teamId || this.props.teamId == "") {
      if (debugThisFile) debugger;

      return <Redirect to="/register" />;
    } else if (this.props.questionReady) {
      if (debugThisFile) debugger;

      return this.questionScreen();
    } else {
      if (debugThisFile) debugger;

      return this.loading();
    }
  }

  questionScreen = () => {
    if (debugThisFile) debugger;

    var a = { chngeMethod: this.answerChangeEvent };
    var props = {
      ...this.props.battleQuiz,
      teamId: this.props.teamId,
      questionNdx: this.props.questionNdx,
    };
    return (
      <div>
        <table className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <tbody>
            <tr>
              <th>
                <DisplayQuestion {...props} />
              </th>
            </tr>
            <tr>
              <th>
                <hr />
              </th>
            </tr>
            <tr>
              <th>
                <div>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    onSubmit={this.answerQuestion}
                  >
                    <br />
                    <DisplayAnswerSet {...a} />
                    <Button variant="primary" type="submit">
                      Answer
                    </Button>
                  </Form>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
}

var tracker = 0;
function mapStateToProps(state, ownProps) {
  if (debugThisFile) debugger;

  //var questionNdx = ownProps.match.params ? ownProps.match.params.ndx : null;
  var question,
    questionNdx = ownProps.location.quiz ? ownProps.location.quiz.questionNdx : null,
    questionReady = false,
    quizComplete = false,
    loadtail = "";

  if (state.battleQuiz.isValid) {
    question = state.battleQuiz.payload.question;
    questionReady = true;
    questionNdx = question.ndx;
    quizComplete = state.battleQuiz.complete ? state.battleQuiz.complete : false;
  }
  console.log("QUIZ COMPLETE: MSTP - QUES", quizComplete);

  for (let n = 0; n <= tracker; n++) {
    loadtail += "..";
  }

  tracker = tracker < 3 ? tracker++ : 0;

  return {
    teamId: ownProps.teamId
      ? ownProps.teamId
      : ownProps.location.quiz
      ? ownProps.location.quiz.team
      : null,
    question,
    questionNdx,
    questionReady,
    runId: state.battleRuns.item._id,
    battleQuiz: state.battleQuiz.payload,
    quiz: { ...ownProps.location.quiz },
    loadtail,
    quizComplete,
  };
}

const mapDispatch = (dispatch) => {
  return {
    loadQuestion: (teamId, battleId, questionNdx) =>
      dispatch(quizSupportActions.getBattleQuestion(teamId, battleId, questionNdx)),
    submitAnswer: (teamId, battleId, runId, questionId, ndx, bookGuess, authorGuess) =>
      dispatch(
        quizSupportActions.answerBattleQuestion(
          teamId,
          battleId,
          runId,
          questionId,
          ndx,
          bookGuess,
          authorGuess
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatch)(BattleQuestion);
