import { createStore, applyMiddleware, compose } from "redux";
import rootReducers from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { sessionService } from "redux-react-session";

const validateSession = (session) => {
  // check if your session is still valid
  return true;
};
const options = {
  refreshOnCheckAuth: true,
  redirectPath: "/home",
  driver: "COOKIES",
  validateSession,
};

export default function configureStore(initialstate) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducers,
    initialstate,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant())) //safteynet to prevent mutable changes
  );
  return store;
}
