import React from "react";
import { connect } from "react-redux";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div>
        <h2>More information</h2>
        <p>More information here..</p>
      </div>
    );
  };
}

const mapState = (state, ownProps) => {
  return {
    state,
    ownProps,
  };
};

export default connect(mapState /*, mapDispatch*/)(AboutPage);
