import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import CardDeck from "react-bootstrap/CardDeck";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

//const decoratedOnClick = useAccordionToggle(eventKey, ()=>);

var debugThisFile = false;
import { storeActions, quizSupportActions } from "../../store/actions/storeActions";
import { Redirect } from "react-router-dom";

class ResultsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ready: false, restart: false };
  }

  componentDidMount() {
    if (debugThisFile) debugger;
    //  if (this.props.runId) this.props.getResults(this.props.runId);
    this.props.refreshAuthors();
    this.props.refreshBooks();
  }

  refreshRes = (event) => {
    if (debugThisFile) debugger;
    var tmp = this.props.runId; // || "5ec1866938b6fa69c41b344a";
    if (this.props.runId) this.props.getResults(tmp);
    this.setState({ ...this.state, ready: true });
  };

  getBookDetails = (bkId, idFld) => {
    if (debugThisFile) debugger;
    var fndBk = this.props.books.find((bk) => bk[idFld] == bkId);
    var fndAuth;
    var ret = { title: "", author: "" };
    if (fndBk) {
      fndAuth = this.props.authors.find((a) => a.internalId == fndBk.author);
      ret = { title: fndBk.name, author: fndAuth ? fndAuth.name : "Unknown Author" };
    }

    return ret;
  };

  getstyle = (ndx, answer, correct) => {
    var retStyle = {
      bg: ndx % 2 == 0 ? "light" : "secondary",
      boarder: "primary",
      text: ndx % 2 == 0 ? "dark" : "light",
      answer: {
        bg: ndx % 2 == 1 ? "light" : "secondary",
        text: ndx % 2 == 1 ? "dark" : "light",
      },
      bookAnswer: { bg: "danger", text: "light" },
      authAnswer: { bg: "danger", text: "light" },
    };

    if (answer.title == correct.title) {
      retStyle.bookAnswer = {
        bg: "success",
        text: "light",
        hdrTxt: "Correct!! Click to reveal",
      };
    } else {
      retStyle.bookAnswer = {
        bg: "danger",
        text: "light",
        hdrTxt: "Click to reveal the answer",
      };
    }
    if (answer.author == correct.author) {
      retStyle.authAnswer = {
        bg: "success",
        text: "light",
        hdrTxt: "Correct!! Click to reveal.",
      };
    } else {
      retStyle.authAnswer = {
        bg: "danger",
        text: "light",
        hdrTxt: "Click to reveal the answer",
      };
    }

    return retStyle;
  };

  renderQuestionRow = (ques, response, correctAnswer) => {
    if (debugThisFile) debugger;
    var answerDetails = this.getBookDetails(response.book, "_id");
    var correctDetails = this.getBookDetails(correctAnswer.book, "internalId");
    var mainKey = response.questionNdx,
      answerKey = mainKey + "_A",
      caKey = mainKey + "_C";

    var cardStyle = this.getstyle(response.questionNdx, answerDetails, correctDetails);

    return (
      <div>
        <Card boarder="primary" bg={cardStyle.bg} text={cardStyle.text}>
          <Accordion.Toggle as={Card.Header} eventKey={mainKey}>
            {response.questionNdx}) {ques.txt}
          </Accordion.Toggle>
          <Card.Body>
            <Card.Text>
              <CardColumns>
                <Card
                  key={answerKey}
                  bg={cardStyle.answer.bg}
                  text={cardStyle.answer.text}
                >
                  <Card.Header> Your Answer: </Card.Header>
                  <Card.Body>Book: {answerDetails.title}</Card.Body>
                </Card>
                <Accordion>
                  <Card bg={cardStyle.bookAnswer.bg} text={cardStyle.bookAnswer.text}>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey={caKey}>
                      {cardStyle.bookAnswer.hdrTxt}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={caKey}>
                      <Card.Body>Book: {correctDetails.title}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </CardColumns>
              <CardColumns>
                <Card
                  key={answerKey}
                  bg={cardStyle.answer.bg}
                  text={cardStyle.answer.text}
                >
                  <Card.Header> Your Answer: </Card.Header>
                  <Card.Body>Author: {answerDetails.author}</Card.Body>
                </Card>
                <Accordion>
                  <Card bg={cardStyle.authAnswer.bg} text={cardStyle.authAnswer.text}>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey={caKey}>
                      {cardStyle.authAnswer.hdrTxt}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={caKey}>
                      <Card.Body> Author: {correctDetails.author} </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </CardColumns>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  };

  renderResults = () => {
    if (debugThisFile) debugger;
    return (
      <div>
        <Accordion>
          {this.props.responses.map((itm) => {
            return (
              <div key={itm.response.questionNdx}>
                {this.renderQuestionRow(itm.ques, itm.response, itm.ca)}
              </div>
            );
          })}
        </Accordion>
      </div>
    );
  };

  restartQuiz = () => this.setState({ ...this.state, restart: true });

  refreshBtn = () => (
    <div>
      <Button variant="primary" type="button" onClick={this.refreshRes}>
        Congratulations. Click me to see your results.
      </Button>
    </div>
  );
  restartBtn = () => (
    <div>
      <Button variant="primary" type="button" onClick={this.restartQuiz}>
        Start a new quiz.
      </Button>
    </div>
  );

  render = () => {
    try {
      if (debugThisFile) debugger;

      if (this.props.loading) {
        //|| !this.state.ready) {
        return (
          <div>
            <div>Loading..</div>
            {this.refreshBtn()}
          </div>
        );
      } else if (!this.state.ready) {
        return <div>{this.refreshBtn()}</div>;
      } else if (this.state.restart) {
        return <Redirect to="/register" />;
      } else {
        if (debugThisFile) debugger;
        return (
          <div>
            {this.restartBtn()}
            {this.renderResults(this.renderQuestionRow)};
          </div>
        );
      }
    } catch (e) {
      return (
        <div>
          <div>something broke</div> {this.refreshBtn()}{" "}
        </div>
      );
    }
  };
}

const mapAppStateToProps = (state, ownProps) => {
  if (debugThisFile) {
    if (debugThisFile) debugger;
  }
  var battleRun = state.battleRuns.item._id,
    loading = state.results.loading;
  return {
    books: state.books.items,
    authors: state.authors.items,
    runId: battleRun,
    loading,
    responses: state.results.responses,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getResults: (runId) => dispatch(quizSupportActions.getResults(runId)),
    refreshBooks: () => dispatch(storeActions.fetchBooks(false)),
    refreshAuthors: () => dispatch(storeActions.fetchAuthors(false)),
  };
};

const BattleResultsPage = connect(mapAppStateToProps, mapDispatch)(ResultsPage);

export default BattleResultsPage;
