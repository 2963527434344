import fetch from "cross-fetch";

import {
  LOAD_AUTHORS_BEGIN,
  LOAD_AUTHORS_FAIL,
  LOAD_AUTHORS_SUCCESS,
  LOAD_AUTHORS_RESET,
} from "./actionTypes";
import { handleResponse, handleError, makeURL } from "../../api/apiUtils";
import { getData } from "./actionBase";

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
export const loadAuthorsSuccess = (authors) => {
  return { type: LOAD_AUTHORS_SUCCESS, payload: authors };
};
export const loadAuthorsFail = (error) => {
  return { type: LOAD_AUTHORS_FAIL, payload: error };
};
export const loadAuthorsBegin = () => {
  return { type: LOAD_AUTHORS_BEGIN };
};
export const resetAuthors = () => {
  return { type: LOAD_AUTHORS_RESET };
};

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

function shouldRefreshAuthors(state) {
  var bRefresh = false;
  if (!state.authors) {
    bRefresh = true;
  } else if (state.authors.items.length === 0) {
    bRefresh = true;
  }

  return bRefresh;
}

const getAuthorList = (simple) => {
  const baseurl = simple
    ? makeURL("entities", "authors")
    : makeURL("entities", "authors", "allfields");
  return getData(baseurl, loadAuthorsBegin, loadAuthorsSuccess, loadAuthorsFail);
};

export const fetchAuthorsIfNeeded = (simple = true) => {
  return (dispatch, getState) => {
    if (shouldRefreshAuthors(getState())) {
      return dispatch(getAuthorList(simple));
    }
    return; // fetch("/authors").then(handleError);
  };
};

export const fetchAuthors = (simple = true, refresh = false) => {
  return (dispatch) => {
    if (refresh) {
      dispatch(resetAuthors());
    }
    return dispatch(getAuthorList(simple));
  };
};
